export const MenuItems = [
  {
    title: 'Education',
    path: '/service-edu',
    cName: 'dropdown-link',
  },
  {
    title: 'Laboratory',
    path: '/service-lab',
    cName: 'dropdown-link',
  },
];
